import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";
import StatisticsCard from "../../components/StatisticsCard/StatisticsCard";
import GetPublicData from "../../queries/Nutitee/GetPublicData";
import GetGlobalStatistics from "../../queries/Nutitee/GetGlobalStatistics";
import GetPedCount from "../../queries/Nutitee/GetPedCount";
import axios from 'axios';
import NumericLabel from 'react-pretty-numbers';

export default function PedestrianGlobals(props) {
    const {t} = useTranslation();

	  const [nutiteeGlobalDataBoxesSettings, setNutiteeGlobalDataBoxesSettings] = useState({
        'average': {'selected': true},
        'speedings': {'selected': true},
        'overspeedings': {'selected': true},
        'max_speed': {'selected': true}
    });

    const [tarkteeData, setTarkteeData] = useState(0);
    const [AVGData, setAVGData] = useState({
        'ped': 0,
        'temp': 0,
        'dirA': 0,
	'dirB': 0
    });

    const numberParams = {
 	 shortFormat: true,
	 justification: 'C'
	};

    var bodyElement;

    useEffect(() => {
const abortController = new AbortController();
        const signal = abortController.signal;

        getPublicData(signal);
	getRoadTempTarkTee();
        return () => {
            abortController.abort()
        }
    }, []);


    const getPublicData = (signal) => {
        GetPedCount(signal).then((res) => {
            setAVGData(prevState => {
                return ({
                    ...prevState,
                    ...res.data.data
                })
            });

	    setTimeout(() => getPublicData(signal), 1000)
        })
    }

    const getRoadTempTarkTee = () => {
	let url = "https://tarktee.ee/tarktee/rest/services/road_weather_stations/MapServer/0/query?f=json&spatialRel=esriSpatialRelIntersects&returnGeometry=false&outFields=*&outSpatialReference=3301&where=objectid=130&objectid=130";

	axios.get(url)
  .then(response => {
//	console.log(response.data.features[0].attributes.road_temp);
	setTarkteeData(response.data.features[0].attributes.road_temp);
	setTimeout(() => getRoadTempTarkTee(), 1000);
  })
  .catch(error => {setTimeout(() => getRoadTempTarkTee(), 1000)})	

    }



    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Statistika")}
                                </h4>
                            </div>                            
                        </div>

                    </div>
                    <Collapse in={true}>
                        <div id="collapsable-pedestrian-card">
                            <div className="card-body">

                                <div className="row text-center">
                                    <div className="col-3">
                                        <StatisticsCard title={t("Turvalisi teeületusi")}
                                                        number={AVGData.ped}
							tooltip="Turvaliste teeületuste kumulatiivne arv reaalajas"
                                                        settings={nutiteeGlobalDataBoxesSettings.average}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Teetemperatuur")}
                                                        number={tarkteeData+"°C"}
							tooltip="Teepinna temperatuur"
                                                        settings={nutiteeGlobalDataBoxesSettings.speedings}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Sõidukeid Mooste suunal")}
                                                        number=<NumericLabel params={numberParams}>{AVGData.dirA}</NumericLabel>
							tooltip="Mooste suunal loendatud sõidukid"
                                                        settings={nutiteeGlobalDataBoxesSettings.overspeedings}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Sõidukeid Saverna suunal")}
                                                        number=<NumericLabel params={numberParams}>{AVGData.dirB}</NumericLabel>
							tooltip="Saverna suunal loendatud sõidukid"
                                                        settings={nutiteeGlobalDataBoxesSettings.max_speed}/>

                                    </div>

                                </div>

                            </div>

                            
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}
