import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";
import StatisticsCard from "../../components/StatisticsCard/StatisticsCard";
import GetPublicData from "../../queries/Nutitee/GetPublicData";
import GetGlobalStatistics from "../../queries/Nutitee/GetGlobalStatistics";


export default function NutiteeGlobals(props) {
    const {t} = useTranslation();

    const [isNutiteeGlobalCardOpen, setIsNutiteeGlobalCardOpen] = useState(props.opened);

    const [isNutiteeGlobalDataLoaded, setIsNutiteeGlobalDataLoaded] = useState(false);

    const [nutiteeGlobalDataBoxesSettings, setNutiteeGlobalDataBoxesSettings] = useState({
        'average': {'selected': true},
        'speedings': {'selected': true},
        'overspeedings': {'selected': true},
        'max_speed': {'selected': true}
    });

    const [nutiteeGlobalStatistics, setNutiteeGlobalStatistics] = useState({
        'average': 0,
        'speedings': 0,
        'overspeedings': 0,
        'max_speed': 0
    });

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        getGlobalStatistics(signal);

        return () => {
            abortController.abort()
        }
    }, [props.startDate, props.endDate]);


    const getGlobalStatistics = (signal) => {
        let startDateISO = props.startDate.toISOString();
        let endDateISO = props.endDate.toISOString();

        setIsNutiteeGlobalDataLoaded(false);

        GetGlobalStatistics(signal, startDateISO, endDateISO).then((res) => {

            if (res) {

                setNutiteeGlobalStatistics(prevState => {
                    return ({
                        ...prevState,
                        ...res.data.data
                    })
                });
            }
            setIsNutiteeGlobalDataLoaded(true);
        })
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Sõidukiiruse ülevaade perioodil ")} {t("")}
                                    <Moment format="DD.MM.yyyy">{props.startDate.toISOString()}</Moment>
                                    -
                                    <Moment format="DD.MM.yyyy">{props.endDate.toISOString()}</Moment>
                                </h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsNutiteeGlobalCardOpen(!isNutiteeGlobalCardOpen)}
                                        aria-controls="collapsable-pedestrian-card"
                                        aria-expanded={isNutiteeGlobalCardOpen}
                                    >
                                        {isNutiteeGlobalCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isNutiteeGlobalCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>
                    <Collapse in={isNutiteeGlobalCardOpen}>
                        <div id="collapsable-pedestrian-card">
                            {isNutiteeGlobalDataLoaded && <div className="card-body">

                                <div className="row text-center">
                                    <div className="col-3">
                                        <StatisticsCard title={t("Keskmine")}
                                                        number={nutiteeGlobalStatistics.average + " km/h"}
							tooltip="Nutiteel mõõdetud keskmine kiirus"
                                                        settings={nutiteeGlobalDataBoxesSettings.average}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Kiiruseületused")}
                                                        number={nutiteeGlobalStatistics.speedings + "%"}
							tooltip="Kiiruseületamised (> 53 km/h)"
                                                        settings={nutiteeGlobalDataBoxesSettings.speedings}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Üle 65 km/h")}
                                                        number={nutiteeGlobalStatistics.overspeedings + "%"}
							tooltip="Kiiruseületamised (> 65 km/h)"
                                                        settings={nutiteeGlobalDataBoxesSettings.overspeedings}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Suurim kiirus")}
                                                        number={nutiteeGlobalStatistics.max_speed + " km/h"}
							tooltip="Suurim mõõdetud kiirus"
                                                        settings={nutiteeGlobalDataBoxesSettings.max_speed}/>

                                    </div>

                                </div>

                            </div>}

                            {!isNutiteeGlobalDataLoaded && <>
                                <div className="card-body" style={{height: "200px"}}>

                                    <div className="row text-center">
                                        <div className="col-12">
                                            <FontAwesomeIcon icon={faRotate} size="10x" className="fa-spin"
                                                             style={{color: "#4d79f6"}}/>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}
