import {CONFIG} from "../../config";
import axios from "axios";

async function ContactQuery(message) {
    const data = {
        'message': message,
    }

    const location = CONFIG.API_URL + "nutitee/contact";
    const settings = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        return await axios.post(location, data, settings);
    } catch (e) {
        return e;
    }
}

export default ContactQuery;
