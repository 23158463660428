import React from 'react';
import './StatisticsCard.css';
import { Tooltip } from 'react-tooltip';

export default function StatisticsCard(props) {
    return (<>
<a data-tooltip-id="my-tooltip" data-tooltip-content={props.tooltip}>
<div className={"box " + (props.settings.selected ? "selected" : "")}>
<Tooltip id="my-tooltip" />





            <h1>{props.title}</h1>
            <h1 className="large-h1">{props.number}</h1>

        </div>
</a>
    </>);
}
