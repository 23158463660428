import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";
import StatisticsCard from "../../components/StatisticsCard/StatisticsCard";
import GetPublicData from "../../queries/Nutitee/GetPublicData";
import GetGlobalStatistics from "../../queries/Nutitee/GetGlobalStatistics";
import GetPedCount from "../../queries/Nutitee/GetPedCount";
import axios from 'axios';
import ContactQuery from "../../queries/Devices/Contact";
import withReactContent from "sweetalert2-react-content";
import swal from 'sweetalert2';
export default function Info(props) {
    const {t} = useTranslation();
	const [isOpen, setIsOpen] = useState(true);
	const [formData, setFormData] = useState("");
	const [enableTagasiside, setEnableTagasiside] = useState(true);
	const apiKeyConfirmMessage = withReactContent(swal);

const postContact = () => {

        ContactQuery(formData).then((res) => {
	setEnableTagasiside(false);
	apiKeyConfirmMessage.fire({
                                        title: <strong>{t("Aitäh!")}</strong>,
                                        html: <i>{t("Tagasiside saadetud!")}</i>,
                                        icon: 'success'
                                    }).then();

        });
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Ootame Sinu tagasisidet ja tähelepanekuid Nutitee kohta")}
                                </h4>
                            </div>
			</div>
                    </div>
                    <Collapse in={isOpen}>
                        <div id="collapsable-pedestrian-card">
                            <div className="card-body">
				<div class="form-group">
    <label for="exampleFormControlTextarea1">Sisesta tagasiside</label>
    <textarea class="form-control" rows="10" value={formData}
      onChange={e => setFormData(e.target.value)}></textarea>
  </div>
{!!enableTagasiside &&
<div className="row">
<div className="col-12 text-end">
<button onClick={() => postContact()}  type="button" class="btn btn-primary btn-lg btn-block">Saada tagasiside</button>
</div>
</div>
}
			   </div> 
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}
