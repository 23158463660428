import {CONFIG} from "../../config";
import axios from "axios";

async function getPedCount(signal) {
    const location = CONFIG.API_URL + "nutitee/getPublicData2";
    const settings = {
        signal: signal,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    try {
        return await axios.get(location, settings);
    } catch (e) {
        return null;
    }
}

export default getPedCount;
