import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";
import StatisticsCard from "../../components/StatisticsCard/StatisticsCard";
import GetPublicData from "../../queries/Nutitee/GetPublicData";
import GetGlobalStatistics from "../../queries/Nutitee/GetGlobalStatistics";
import GetPedCount from "../../queries/Nutitee/GetPedCount";
import axios from 'axios';

export default function Info(props) {
    const {t} = useTranslation();
	const [isOpen, setIsOpen] = useState(props.opened);
    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Mis on Nutitee?")}
                                </h4>
                            </div>                            
                        
			<div
                                className="col-6 text-end"
                                style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsOpen(!isOpen)}
                                        aria-controls="collapsable-pedestrian-card"
                                        aria-expanded={isOpen}>

                                        {isOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div></div>
                    </div>
                    <Collapse in={isOpen}>
                        <div id="collapsable-pedestrian-card">
                            <div className="card-body">

<h3>Mis on Nutitee?</h3>
Põlva Nutitee on 2,7-kilomeetrine teelõik Põlva kesklinnas Kesk ja Jaama tänaval, hõlmates 
sõidukite keskmise kiiruse mõõtmise lahendust ning nutikaid ülekäiguradasid. Nutiteel on 
viis sõidukite numbrituvastuspunkti keskmise kiiruse arvutamiseks ning 14 nutika 
ohutussüsteemiga ülekäigurada. Sõiduki keskmine kiirus kuvatakse sõidukijuhile mõlemas 
nutitee otsas oleval digitaalsel infotahvlil. Nutitee osaks on ka mõõtepunkt 
keskkonnaandmete kogumiseks.
<h3>Kuidas Nutiteel keskmist kiirust mõõdetakse?</h3>
Nutitee keskmise kiiruse arvutamise süsteem põhineb numbrituvastuskaameratele, mis on 
paigutatud viide punkti kogu Nutitee ulatuses. Iga kaamera tuvastab mööduva sõiduki 
registreerimisnumbri ning salvestab süsteemi jaoks anonüümse ID-ga täpse aja, millal 
sõiduk kaamerast möödus. Kui sõiduk on läbinud vähemalt kaks numbrituvastuspunkti, 
arvutatakse tema liikumise kiirus järgmiste sammude kaudu:<br /><br />
<ol>
<li>
Vahemaa arvestamine: Kaamerate vahelise teelõigu pikkus on süsteemi jaoks 
eelnevalt teada ja fikseeritud. See vahemaa on kiiruse arvutamise aluseks.
</li>
<li>
Vahemaa läbimisaja mõõtmine: Kui sõiduk möödub esimesest 
numbrituvastuskaamerast, registreerib süsteem täpse aja. Sama toimub järgmis(t)e 
numbrituvastuskaamera(te)ga. Eelneva info põhjal arvutab süsteem kahe punkti 
vahelise teelõigu läbimiseks kulunud aja.
</li>
<li>
Keskmise sõidukiiruse arvutamine: Sõiduki kiirus arvutatakse kaameratevahelise 
vahemaa jagamisel selle läbimiseks kulunud ajaga. Rohkem kui kahe tuvastuspunkti 
läbimisel arvutatakse nutitee lõpus kõigi lõikude keskmine kiirus.
</li>
<li>
Keskmise kiiruse tagasisidestamine sõidukijuhtidele: Nutitee lõpus olevale 
infotahvlile kuvatakse sõiduki keskmine kiirus koos positiivse või negatiivse 
sõnumiga lähtuvalt sellest, kas kiirus jäi lubatud piiridesse või ületas seda. Keskmise
kiiruse numbrilist väärtust ei kuvata alates kiirusest, mis ületab 65 km/h. 
Tagasisidestamisel arvestatakse nutitee erinevatel lõikudel kehtestatud 
piirkiirustega vastavalt 40 km/h või 50 km/h, mis on süsteemi tööks eelnevalt 
fikseeritud.</li></ol>
			   </div> 
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}
