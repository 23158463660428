import React, {useEffect, useState} from 'react';
import './DeployedDevicesMap.css';
import {ReactComponent as CrosswalkIcon} from "../../../assets/icons/pedestrian_crosswalk.svg";
import {useTranslation} from "react-i18next";
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMinus,
    faPlus,
    faCog,
    faChartSimple,
    faExclamationTriangle,
    faTemperature0
} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {Collapse} from "react-bootstrap";
import {ButtonGroup} from "@mui/material";
import ReactDOMServer from 'react-dom/server';
import Leaflet from 'leaflet';
import moment from "moment-timezone";
import {Link, useNavigate} from "react-router-dom";

export default function DeployedDevicesMap({devices, selectedDevice}) {
    const {t} = useTranslation();
    const [isCardOpen, setIsCardOpen] = useState(true);

    const navigate = useNavigate();

    const createCustomIconWithName = (name, color, notifications) => {
        if (name.length > 8) {
            name = name.substring(0, 5) + "...";
        }

        const iconStyle = {
            filter: `drop-shadow(1px 1px 1px ${color}) drop-shadow(-1px -1px 1px ${color})`
        };

        let iconHTML = ReactDOMServer.renderToString(<div style={{textAlign: "center"}}>
                <span className='outlined-text'>{name}
                    {notifications ? <FontAwesomeIcon title={t("Device has notifications")}
                                                      icon={faExclamationTriangle}
                                                      color="red">
                    </FontAwesomeIcon> : null}
                    <br/></span>
            <CrosswalkIcon className="traffic-icon" style={iconStyle}/>
        </div>);

        return new Leaflet.DivIcon({
            html: iconHTML, iconSize: [60, 50], iconAnchor: [30, 50],
        });
    }

    const Recenter = ({lat, lng}) => {
        const map = useMap();
        useEffect(() => {
            map.flyTo([lat, lng], 14);
            map.closePopup();

        }, [lat, lng]);
        return null;
    }

    const isDeviceOnlineMummu = (device_id, heartbeat) => {
        let online = false;

        let latestAUptime = null;
        // miinus viis mintsa
        let now = moment(Date.now()).subtract(5, 'minutes');

        for (const [, value] of Object.entries(heartbeat)) {
            if (value.core_id === "A") {
                latestAUptime = value.updated_at;
            }
        }

        if (latestAUptime) {
            if (moment(now).isBefore(latestAUptime)) {
                online = true;
            }
        }

        return {
            "online": online,
            "latest_a_uptime": latestAUptime ? moment(latestAUptime).format('YYYY-MM-DD HH:mm') : t("Never"),
            "has_sent_heartbeat": !!latestAUptime
        };
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-9">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>{t("Nutitee kaart")}</h4>
                            </div>
                            <div className="col-3 text-end" style={{padding: "0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsCardOpen(!isCardOpen)}
                                        aria-controls="collapsableMapCard"
                                        aria-expanded={isCardOpen}
                                    >
                                        {isCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>
                    <Collapse in={isCardOpen}>
                        <div id="collapsableMapCard">
                            <div className="card-body">
                                <MapContainer zoom={14} center={[58.0578407, 27.0644881]} scrollWheelZoom={false}>
                                    {selectedDevice && <Recenter
                                        lat={selectedDevice?.coordinates ? selectedDevice?.coordinates.split(";")[0] : 58.36394}
                                        lng={selectedDevice?.coordinates ? selectedDevice?.coordinates.split(";")[1] : 26.76912}/>}
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {devices && <>
                                        {devices.map((device) => {
                                            let coordinates = device.coordinates.split(";");

                                            return (<Marker
                                                position={coordinates}
                                                icon={createCustomIconWithName(device.name, device.id === selectedDevice?.id ? "rgba(21, 255, 0)" : "rgba(46, 46, 46, 0.3)", device.device_notifications_count)}>
                                                <Popup>
                                                                    <span
                                                                        style={{fontWeight: "bold"}}>{device.name}{" "}
                                                                        {device.device_notifications_count ?
                                                                            <Link to="/notifications">
                                                                                <FontAwesomeIcon
                                                                                    title={t("Device has notifications")}
                                                                                    icon={faExclamationTriangle}
                                                                                    color="red">
                                                                                </FontAwesomeIcon>
                                                                            </Link> : null}
                                                                    </span><br/>
                                                    {"Speed trigger: "}{device.speed_trigger}{" km/h"}<br/>
                                                    {"Modification: "}{device.modification}

                                                </Popup>
                                            </Marker>)
                                        })}
                                    </>}
                                </MapContainer>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>);
}
